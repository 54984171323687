import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import useEventCallback from '@engined/client/hooks/useEventCallback.js';
import { NOTHING } from '@engined/client/locales.js';
import { FormControl, ListSubheader, MenuItem, Select, SelectProps } from '@mui/material';
import Input from '@engined/client/components/table/Input.js';
import React from 'react';
import { FilterProps } from 'react-table';

export interface Option {
  label: React.ReactNode;
  value: string;
}

export interface Group {
  label: React.ReactNode;
  options: Option[];
}

export type Options = (Option | Group)[];

interface OwnProps {
  options: Options;
  multiple?: boolean;
}

function SelectFilter<D extends object>({
  options,
  column: { filterValue, setFilter, id },
  multiple = false,
}: FilterProps<D> & OwnProps) {
  const { t } = useLocale();
  const onChange = useEventCallback<SelectProps['onChange']>((event) => {
    if (multiple) {
      const value = event.target.value as string[];
      setFilter(value.join(','));
    } else {
      const value = event.target.value as string;
      setFilter(value);
    }
  });

  return (
    <FormControl variant="outlined" margin="none" fullWidth>
      <Select
        value={multiple ? (filterValue || '').split(',').filter(Boolean) : filterValue || ''}
        onChange={onChange}
        input={<Input />}
        id={id}
        multiple={multiple}
      >
        {!multiple && (
          <MenuItem value="">
            <em>{t(NOTHING)}</em>
          </MenuItem>
        )}
        {options.map((opt, i) =>
          'options' in opt ? (
            [
              <ListSubheader key={i}>{opt.label}</ListSubheader>,
              ...opt.options.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              )),
            ]
          ) : (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.label}
            </MenuItem>
          ),
        )}
      </Select>
    </FormControl>
  );
}

SelectFilter.displayName = 'SelectFilter';

export default SelectFilter;
